import { CreateElement, VNode } from 'vue';
import { FilterSettings } from '@/features/om27/types/signoff.types';

export interface SubSubCategory {
  name: string;
  logicalBotIds: number[];
}

export interface SubCategory {
  name: string;
  subsubcategories: SubSubCategory[];
}

export interface DashboardCategory {
  name: string;
  subcategories: SubCategory[];
}

export interface SubRecord {
  botId: number;
  fileName: string;
  machineNameShort: string;
  botRunnerUserId: string;
  latestStartDate: string;
  latestErrorDate: string;
  latestFinishDate: string;
  latestErrorMsg: string;
  latestConfirmedStartDate: string;
  latestConfirmedStartMsg: string;
  latestTrustedFinishedDate: string;
  latestTrustedFinishedMsg: string;
  simpleAutoSignoffFlag?: boolean;
}

// if more types are added, the routing will need to be updated
export type TicketType = 'blocking' | 'intermittent' | 'nonblocking';

export interface BlockingTicket {
  ticketNumber: string;
  ticketUrl: string;
  status: 'OPEN' | 'CLOSED';
}

export interface Ticket {
  ticketType: 'BLOCKING' | 'INTERMITTENT' | 'ENHANCEMENT';
  daysOpen: number;
  ticketNumber: string;
  ticketUrl: string;
  status: 'OPEN' | 'CLOSED';
}

export interface LogicalBot {
  logicalBotId: number;
  botName: string;
  status: string;
  sox: string;
  soxDescription: string;
  soxDocumentReference: string;
  soxHumanApprover: string;
  assignmentGroup: string;
  folderName: string;
  schedule: string;
  lenientAutoSignoffFlag: boolean;
  lenientApprover: string;
  autoSignoffSchedule: string[],
  additionalInfo: string;
  assignedUsers: string[];
  triggerType: string;
  latestStartDate: string;
  latestErrorDate: string;
  latestFinishDate: string;
  currentSignOffNeededByDate: string;
  currentSignOffStartDate: string;
  signOffId: number;
  latestErrorMsg: string;
  latestConfirmedStartDate: string;
  latestConfirmedStartMsg: string;
  latestTrustedFinishedDate: string;
  latestTrustedFinishedMsg: string;
  blockingTickets: BlockingTicket[];
  tickets: Ticket[];
  subRecords: SubRecord[];
  actionNeeded: boolean;
  statusTags: (string | null)[];
  validationReason: string | null;
}

export interface UnassignedBot {
  botId: number;
  botName: string;
  host: string;
  hostShort: string;
  botRunnerId: string;
  lastRunDate: Date;
  paths: string[];
}

export interface RpaDashboard {
  userPreferences: UserPreferences;
  categories: DashboardCategory[];
  logicalBots: LogicalBot[];
}

export interface RpaDashboardResponse {
  userPreferences: UserPreferences;
  categories: DashboardCategory[];
  logicalBots: Partial<LogicalBot & { subRecords: Partial<SubRecord>[] }>[];
}

export interface UserPreferences {
  preferred_timezone: string;
  timezone_short: string;
  preferredTimeZone?: string;
  IANATimeZone?: {
    shortzone: string;
  };
}

export interface DashboardSignOff {
  evidenceType: string;
  evidenceDetails: string;
  logicalBotId: number;
  ticketNumber?: string;
  signOffEffectiveDate: string;
  signOffId: number;
}

export interface OpenTicketRequest {
  details: string;
  botId: number;
  logicalBotId: number;
  attachExistingTicketNumber?: string;
}

export type CustomCell<S, T> = (
  data: { row: S; column: T; rowIndex: number },
  h: CreateElement,
) => VNode | VNode[];

export interface SignOffDataEvidenceFile {
  create_dt: string;
  file_id: string;
  file_name: string;
  file_path: string;
}
export interface SignOffData {
  userPreferences: UserPreferences;
  evidenceTemplate: string;
  logData: LogData[];
  signoffStartDate: string;
  signoffNeededByDate: string;
  closure?: Closure;
  blockingTickets: BlockingTicket[];
  priorEffectiveDate: string;
  signofffor: number;
  signofffordesc: string;
  tickets?: Ticket[];
  supportTeam: string;
  files: SignOffDataEvidenceFile[]
}

export interface Closure {
  closureDate: string;
  signoffEffectiveDate: string | null;
  evidenceType: string;
  evidenceDetails: string;
  signoffHumanUserMudId: string;
  signoffHumanUserName: string;
  verificationDetails?: VerificationDetails[];
  ticketNumber?: string;
}

export interface VerificationDetailsComment {
  create_dt: string;
  sign_off_verification_id: number;
  verifyCommentHumanUserMudId: string;
  verifyCommentHumanUserName: string;
  verifycomment: string;
}

export interface VerificationDetails {
  verifyHumanUserMudId: string;
  verifyHumanUserName: string;
  verificationNotes: string;
  verificationStatus: string;
  verificationStatusCode: string;
  verificationDate: string;
  ticketNumber?: string;
  ticketUrl?: string;
  comments: VerificationDetailsComment[];
}

export interface SignOffHistoryItem {
  signoffId: number;
  signoffEffectiveDate: string;
  blockingTickets: BlockingTicket[];
  tickets: Ticket[];
  signoffStartDate: string;
  signoffNeededByDate: string;
  closureDate: string;
  signoffHumanUserMudId: string;
  signoffHumanUserName: string;
  evidenceDetails: string;
  evidenceType: string;
  verifyHumanUserMudId: string | null;
  verifyHumanUserName: string | null;
  lastVerificationStatus: string | null;
  lastVerificationStatusCode: string | null;
  lastVerificationNotes: string | null;
  lastVerificationDate: string | null;
  signofffor: number;
  signofffordesc: string;
  verifyTicket: Partial<Ticket>;
  signoffTicket: Partial<Ticket>;
}

export interface SignOffDataResponse {
  userPreferences: UserPreferences;
  evidenceTemplate: string;
  signoffStartDate: string;
  signoffNeededByDate: string;
  blockingTickets?: BlockingTicket[];
  logData: Partial<LogData>[];
}

export interface LogData {
  botLogId: number;
  botId: number;
  fileName: string;
  machineNameShort: string;
  botRunnerUserId: string;
  startDate: string;
  startMsg: string;
  errorDate: string;
  finishDate: string;
  finishMsg: string;
  errorMsg: string;
  confirmedStartDate: string;
  confirmedStartMsg: string;
  trustedFinishedDate: string;
  trustedFinishedMsg: string;
  nonNullDateField: string;
}

export interface SignOffFilters {
  machineNameShort: string[];
  fileName: string[];
}

export interface ColumnData<S> {
  field: keyof S;
  title: string;
  fixed?: 'left' | 'right';
  width?: number;
  type?: 'expand';
  renderBodyCell?: CustomCell<S, ColumnData<S>>;
  key?: string;
  align?: 'left' | 'center';
  sortBy?: 'asc' | 'desc' | '';
  filter?: FilterSettings;
}

export type SearchRecord = LogicalBot | UnassignedBot;

export interface SearchConfig {
  name: string;
  routeName: string;
  storeData: () => SearchRecord[];
  searchKeys: string[];
  promise: () => Promise<unknown>;
}

export interface EffectiveDate {
  key: SignOffActions;
  error: string | null;
  dates: {
    type: string;
    value: string;
    formatted: string;
  }[];
  tickets?: string[];
}

interface EffectiveDatesResponseModel {
  error: string;
  tickets?: string[];
  effectivedates: {
    type: string;
    effectivedate: string;
  }[];
}

export interface EffectiveDatesResponse {
  IANATimeZone: {
    shortzone: string;
    citycode: string;
    voffset: string;
  };
  botfailed: EffectiveDatesResponseModel;
  noactivity: EffectiveDatesResponseModel;
  botsuccess: EffectiveDatesResponseModel;
}

export enum SignOffActions {
  BotFailed = 'botfailed',
  NoActivity = 'noactivity',
  BotSuccess = 'botsuccess',
}

export enum LogicalBotStatuses {
  Overdue = 'danger',
  Blocked = 'danger',
  Ready = 'success',
  Disabled = 'light',
  Waiting = 'warning',
}

export interface SignOffInfoResponse {
  signOffId: number;
  logicalBotId: number;
  isSignoffClosed: boolean;
}

export interface SignOffEvidenceFile {
  fileName: string;
  filePath: string;
}

export interface ScheduledReport {
  userPreferences?: {
    preferredTimeZone: string;
  };
  reportName: string;
  toAddressees: string;
  ccAddressees: string | null;
  subject: string;
  emailTemplate: string | null;
  sun: 0 | null;
  mon: 1 | null;
  tue: 2 | null;
  wed: 3 | null;
  thu: 4 | null;
  fri: 5 | null;
  sat: 6 | null;
  firstRunHr: number;
  firstRunMin: number;
  secondRunHr: number | null;
  secondRunMin: number | null;
  sqlQuery: string;
  parameterized: boolean;
  disabled: boolean;
}

export interface ScheduledReportParameters {
  mudId: string;
  startDateUTC: string;
  endDateUTC: string;
}

export interface ScheduledReportViewResponse {
  userPreferences?: {
    preferredTimeZone: string;
  };
  reportName: string;
  reportData: string;
}

export interface SignOffEvidenceFile {
  fileName: string;
  filePath: string;
}
